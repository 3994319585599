.App {
  text-align: center;
}

/* Logo-Stile */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header-Stile */
.App-header {
  background-color: #282c34; /* Hintergrundfarbe für den Standardmodus */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; /* Textfarbe für den Standardmodus */
}

/* Link-Stile */
.App-link {
  color: #61dafb; /* Linkfarbe für den Standardmodus */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
  .App-header {
    background-color: #1c1f24; /* Dunklerer Hintergrund für den Dark Mode */
    color: #ffffff; /* Hellerer Text für den Dark Mode */
  }

  .App-link {
    color: #9cdefb; /* Etwas hellere Linkfarbe für bessere Sichtbarkeit im Dark Mode */
  }
}
